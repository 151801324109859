












import Vue from "vue";
import JobStatGrid from "@/components/job/JobStatGrid.vue";
import JobTree from "@/components/job/JobTree.vue";

interface IData {
    treeLoaded: boolean;
}

export default Vue.extend({
    components: {
        JobStatGrid,
        JobTree,
    },
    data(): IData {
        return {
            treeLoaded: false,
        };
    },
    methods: {
        onTreeLoaded() {
            this.treeLoaded = true;
        },
    },
});
