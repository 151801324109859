

































































import Vue from 'vue';
import LoadingErrorable from '@/models/util/LoadingErrorable';
import { IJobScaling, IJobScalingV1, IJobScalingV0 } from '@/models/jobs/IJobScaling';
import JobProvider from '@/api/JobProvider';
import IJob from '@/models/jobs/IJob';
import Loader from "@/components/util/Loader.vue";
import SpriteIcon from "@/components/util/SpriteIcon.vue";
import UiString from "@/components/uistring/UiString.vue";

interface IData {
    scalings: LoadingErrorable<IJobScaling[], any>;
    groups: IJobGroup[];
    isV0: boolean;
}

interface IStatJob extends IJob {
    stats: IJobScaling|null;
}

interface IJobGroup {
    representative: IJob;
    children: IStatJob[];
}

export default Vue.extend({
    components: {
        Loader,
        SpriteIcon,
        UiString,
    },
    data(): IData {
        return {
            scalings: new LoadingErrorable(),
            groups: [],
            isV0: true,
        }
    },
    mounted() {
        this.fetchData();
    },
    computed: {
    },
    methods: {
        fetchData() {
            this.scalings.startLoad();
            JobProvider.getAllJobs().then((jobs) => {
                this.groups = this.categorizeJobsByBase(jobs);
                return JobProvider.getAllJobsScaling();
            }).then((scalings) => {
                for (let g of this.groups) {
                    g.children.forEach(((j) => {
                        j.stats = this.getScalingForJob(j.id, scalings);
                    }));
                }

                this.scalings.done(scalings);

                this.computeIsV0();
            })
        },

        categorizeJobsByBase(jobs: IJob[]): IJobGroup[] {
            // Find all base jobs
            const ret = jobs.filter((j) => j.jobNumber == 0).map((j: IJob): IJobGroup => {
                return {
                    representative: j,
                    children: [],
                };
            });

            ret.forEach((g) => {
                const rep = g.representative;
                const maxJobNumber = jobs.reduce((pv, v) => Math.max(pv, v.jobNumber), 0);
                g.children.push(...(jobs.filter((j) => j.id != rep.id && j.baseClass == rep.baseClass && j.jobNumber == maxJobNumber)
                    .map((j) => {
                        const r = j as IStatJob;
                        r.stats = null;
                        return r;    
                    })));
            });

            return ret;
        },

        getScalingForJob(jobId: number, scalings: IJobScaling[]): IJobScaling {
            return scalings.find((s) => s.jobId == jobId)!;
        },

        computeIsV0() {
            const first = this.groups[0];
            if (first) {
                const firstChild = first.children[0];
                if (firstChild && firstChild.stats) {

                    this.isV0 = firstChild.stats.scalingVersion !== 1;
                    return;
                }
            }

            this.isV0 = true;
        }
    }
});
