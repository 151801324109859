




































import Vue from 'vue';
import LoadingErrorable from '@/models/util/LoadingErrorable';
import SkillProvider from '@/api/SkillProvider';
import Loader from "@/components/util/Loader.vue";
import ISkillTreeEntry from '@/models/skills/ISkillTreeEntry';
import SkillStubLink from '@/components/skill/SkillStubLink.vue';
import JobIcon from '@/components/job/JobIcon.vue';
import JobProvider from "@/api/JobProvider";
import IIconInfo from '../../models/util/IIconInfo';
import IJob from '../../models/jobs/IJob';

interface IData {
    tree: LoadingErrorable<ISkillTreeEntry[], any>;
    jobInfo: IJob|null;
}

export default Vue.extend({
    components: {
        Loader,
        SkillStubLink,
        JobIcon,
    },
    props: {
        "jobId": {
            type: Number as () => number,
        },
    },
    data(): IData {
        return {
            tree: new LoadingErrorable(),
            jobInfo: null,
        }
    },
    computed: {
        skills(): ISkillTreeEntry[] {
            if (this.tree.value) {
                if (this.jobInfo && this.jobInfo.jobNumber === 2) {
                    return this.tree.value.filter((s) => !s.isAwakened);
                } else {
                    return this.tree.value;
                }
            }

            return [];
        },
        slots(): (ISkillTreeEntry|null)[] {
            const ret = [];
            for (let i = 0; i < (4*7); ++i) {
                ret[i] = this.skills.find((s) => s.index === i) || null;
            }

            return ret;
        },
        awakeningSkills(): ISkillTreeEntry[] {
            if (this.tree.value && this.jobInfo && this.jobInfo.jobNumber === 2) {
                return this.tree.value.filter((s) => s.isAwakened);
            }

            return [];
        },
        awakeningSlots(): (ISkillTreeEntry|null)[] {
            const ret = [];
            for (let i = 0; i < (4*7); ++i) {
                ret[i] = this.awakeningSkills.find((s) => s.index === i) || null;
            }

            return ret;
        },
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        async fetchData() {
            this.tree.startLoad();
            try {
                this.jobInfo = null;
                try {
                    this.jobInfo = await JobProvider.getJob(this.jobId);
                } catch (error) {
                    console.log('Failed to load job', error);
                }

                this.tree.done(await SkillProvider.getSkillTreeInfo(this.jobId));
            } catch (error) {
                console.log(error);
                this.tree.failed(error);
            }
        },
        async fetchJob() {
        }
    }
})
