













import Vue from 'vue';
import IIconInfo from '@/models/util/IIconInfo';
export default Vue.extend({
    props: {
        iconInfo: {
            type: Object as () => IIconInfo,
        },
        sizeOverride: {
            type: Number as () => number,
        }
    },
    computed: {
        resize(): number {
            if (this.sizeOverride) {
                const n = Number(this.sizeOverride);
                return Math.floor(n / this.iconInfo.size * 100) / 100;
            }

            return 1;
        },
        sizePx(): number {
            return this.iconInfo.size * this.resize;
        }
    }
});
